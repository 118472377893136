import clsx from 'clsx'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useRef, useState } from 'react'
import useSearchspringSuggest from '../../../../hooks/pageType/category/useSearchspringSuggest'
import useAuthStateQuery from '../../../../hooks/useAuthStateQuery'
import useHeaderSearchSpringQuery from '../../../../hooks/useHeaderSearchSpringQuery'
import { featureFlags } from '../../../../lib/publicConfig'
import { SiteBanner } from '../../banner/SiteBanner'
import ErrorBoundary from '../../ErrorBoundary'
import { DesktopSubheader } from '../../header/DesktopSubheader'
import StaticHeader from '../../header/StaticHeader'
import Footer from '../../legacy/footer'
import ScrollToTop from '../../legacy/footer/ScrollToTop'
import { useUI } from '../../ui-context'
import { CustomerAnalytics } from './CustomerAnalytics'
import type { MainLayoutProps } from './index'
import MainLayoutBreadcrumbs from './MainLayoutBreadcrumbs'

const DynamicAuthModal = dynamic(() => import('../../auth-modal/AuthModal'), { ssr: false })
const DynamicAutoshipModal = dynamic(() => import('../../autoship/AutoshipModal'), { ssr: false })
const DynamicHolidayShippingModal = dynamic(
  () => import('../../holiday-shipping-modal/HoldayShippingModal'),
  { ssr: false }
)

export default function ResponsiveLayout({
  staticProps,
  children,
  crumbs,
  className = '',
  contentClassName = '',
  useBodyPadding = true,
  useContainer = true,
  subMenu = null,
}: MainLayoutProps) {
  const { displayAuthModal, displayAutoshipModal, displayHolidayShippingModal, closeSearchMenu } =
    useUI()
  const { categoryTree, featuredBrands } = staticProps || {}

  const staticHeaderRef = useRef<HTMLElement | null>(null)
  const desktopSubHeaderRef = useRef<HTMLElement | null>(null)

  const [query, setQuery] = useState('')

  const { data: staticHeaderSuggestData } = useSearchspringSuggest(query)

  const { data: customer } = useAuthStateQuery()

  const [filter, setFilter] = useState<[string, string] | null>(null)
  const [selectedSuggestion, setSelectedSuggestion] = useState<string>()

  const searchData = useHeaderSearchSpringQuery({
    selected: selectedSuggestion,
    filter: filter ?? undefined,
    enabled: Boolean(query.trim()),
    categoryTree: staticProps.categoryTree,
  })

  const { asPath } = useRouter()

  const [showHelpMenu, setShowHelpMenu] = useState(false)

  return (
    <>
      <div className={clsx(['flex min-h-full flex-col items-stretch overflow-clip', className])}>
        <nav className="sticky top-0 z-50 flex max-h-screen w-full flex-col">
          <StaticHeader
            ref={staticHeaderRef}
            categories={categoryTree}
            searchQuery={query}
            onSearchQueryChange={setQuery}
            suggestData={staticHeaderSuggestData}
            filter={filter}
            onFilterChange={setFilter}
            onSelectedSuggestionChange={setSelectedSuggestion}
            selectedSuggestion={selectedSuggestion}
            searchData={searchData}
            showHelpMenu={showHelpMenu}
            onShowHelpMenuChange={setShowHelpMenu}
          />
          <DesktopSubheader
            ref={desktopSubHeaderRef}
            categories={categoryTree}
            featuredBrands={featuredBrands}
            asPath={asPath}
            displayPhoneOutageAlert={featureFlags.displayPhoneOutageAlert}
            onCloseSearchMenu={closeSearchMenu}
          />
        </nav>

        <SiteBanner />
        {crumbs && <MainLayoutBreadcrumbs crumbs={crumbs} />}
        {subMenu}
        <ErrorBoundary>
          <main
            className={clsx(
              'shrink-0 grow print:py-1',
              useContainer && 'container px-4',
              useBodyPadding && 'py-8',
              contentClassName
            )}
          >
            {children}
          </main>
        </ErrorBoundary>
        <ScrollToTop />
        <Footer />
      </div>
      <CustomerAnalytics customer={customer} />
      {displayAuthModal && <DynamicAuthModal />}
      {displayAutoshipModal ? <DynamicAutoshipModal /> : null}
      {displayHolidayShippingModal ? <DynamicHolidayShippingModal /> : null}
    </>
  )
}
