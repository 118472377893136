import clsx from 'clsx'
import { ChevronIcon } from '../icons/ChevronRightIcon'
import Cart from './Cart'

export type CartButtonProps = {
  quantity?: number
  ariaControls?: string
  ariaHasPopup?: boolean
  onClick?: () => void
  className?: string
}

export default function CartButton({
  quantity,
  ariaControls,
  ariaHasPopup,
  onClick,
  className,
}: CartButtonProps) {
  return (
    <button
      aria-label="Cart"
      type="button"
      className={clsx(
        'flex flex-row items-center space-x-3 rounded text-ds-neutral focus:outline-none focus:ring-2 focus:ring-ds-yellow',
        className
      )}
      aria-haspopup={ariaHasPopup}
      aria-controls={ariaControls}
      onClick={onClick}
    >
      <Cart quantity={quantity} />

      <span className="flex flex-row items-center space-x-2 text-lg font-bold">
        <span>Cart</span>
        <ChevronIcon className="block w-2 rotate-90 transform" />
      </span>
    </button>
  )
}
